import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';

import useI18n from 'lib/use-i18n';

import InputField from './input-field';
import TextareaField from './textarea-field';
import SelectField from './select-field';
import RadioField from './radio-field';

import { Link } from 'react-router-dom';

const convertParams = (params) => (
  { ...params, channel_id: (params.channel_id && params.channel_id.value) }
);

const channelsToOptions = (channels) => channels.map((channel) => (
  { value: channel.id, label: channel.name }
));

const FrameContractForm = ({ initialValues, onSubmit, channels, cancelTo }) => {
  const { translate } = useI18n('frame_contracts');
  const PRICING_TYPE_OPTIONS = [
    { value: '0', label: translate('.pricing_types.contingent') },
    { value: '1', label: translate('.pricing_types.flatrate') }
  ];

  return <div className="container-fluid padded">
    <Form initialValues={initialValues}
          onSubmit={(params) => onSubmit(convertParams(params))}
          render={({ handleSubmit, submitting, form }) => (
            <form className="form-horizontal" onSubmit={handleSubmit}>
              <SelectField name="channel_id" label={translate('.fields.channel_id')}
                           options={channelsToOptions(channels)} required />
              <InputField name="code" label={translate('.fields.code')} required />
              <TextareaField name="description" type="textarea" label={translate('.fields.description')} />
              <InputField name="posting_email" label={translate('.fields.posting_email')}
                          placeholder={translate('.placeholders.posting_email')} />
              <RadioField name="pricing_type" label={translate('.fields.pricing_type')}
                          options={PRICING_TYPE_OPTIONS} />
              <InputField name="posting_price" label={translate('.fields.posting_price')} type="number" />
              <InputField name="total_amount" label={translate('.fields.total_amount')} type="number" />
              <InputField name="activates_at" label={translate('.fields.activates_at')} type="date" />
              <InputField name="expires_at" label={translate('.fields.expires_at')} required type="date" />

              <div className="form-group">
                <div className="col-sm-offset-3 col-sm-9">
                  <Link to={cancelTo} className="btn btn-danger">
                    <FormattedMessage id="buttons.cancel" />
                  </Link>
                  <button type="submit" className="btn"
                          onClick={() => form.change('state', 'draft')}>
                    <FormattedMessage id="frame_contracts.save_draft" />
                  </button>
                  <button type="submit" className="btn btn-primary"
                          onClick={() => form.change('state', 'requested')}>
                    <FormattedMessage id="frame_contracts.save_requested" />
                  </button>
                </div>
              </div>
            </form>
          )} />
  </div>;
}

FrameContractForm.propTypes = {
  // Initial values to be used for the form.
  initialValues: PropTypes.object,

  // Function to handle passing the submitted form values to the server
  onSubmit:      PropTypes.func.isRequired,

  // List of channels, returned by useChannels
  channels:      PropTypes.array.isRequired,

  // Route to navigate to when the cancel button is clicked
  cancelTo:      PropTypes.string.isRequired
};

export default FrameContractForm;
