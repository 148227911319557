import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import reportWebVitals from './report-web-vitals';
import axios from 'axios';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import Intl from './intl';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import App from './layouts/app';
import Sessions from './layouts/sessions';
import Companies from './layouts/companies';
import Company from './layouts/company';
import Admin from './layouts/admin';

import ListCompanies from './routes/app/companies';
import CreateCompany from './routes/app/companies/new';
import ShowCompany from './routes/app/company';
import EditCompany from './routes/app/company/edit';

import ListUsers from './routes/app/company/users';
import CreateUser from './routes/app/company/users/new';
import ListFrameContracts from './routes/app/company/frame-contracts';
import CreateFrameContract from './routes/app/company/frame-contracts/new';
import ShowFrameContract from './routes/app/company/frame-contract';
import EditFrameContract from './routes/app/company/frame-contract/edit';

import ListOrders from './routes/app/orders';

import Settings from './routes/app/settings';

import SignIn from './routes/sessions/new';
import SignInOtp from './routes/sessions/otp';
import ForgotPassword from './routes/sessions/forgot';
import ConfirmUser from './routes/sessions/confirm';
import ResetPassword from './routes/sessions/reset';

import ListAdminUsers from './routes/admin/users';
import CreateAdminUser from './routes/admin/users/new';
import { Provider, ErrorBoundary } from '@rollbar/react';

axios.defaults.withCredentials = true;

const root = ReactDOM.createRoot(document.getElementById('root'));
const rollbarConfig = {
  accessToken: process.env.X_ROLLBAR_TOKEN,
  environment: process.env.X_APP_ENV,
  captureUncaught: true,
  captureUnhandledRejections: true,
  scrubFields: ['email', 'token', 'first_name', 'last_name'],
  captureIp: 'anonymize'
};

root.render(
  <React.StrictMode>
    <Intl>
      <BrowserRouter>
        <Provider config={rollbarConfig}>
          <ErrorBoundary>
            <Routes>
              <Route path="/" element={<App />}>
                <Route index element={<Navigate to="/companies" />} />

                <Route path="companies" element={<Companies />}>
                  <Route index element={<ListCompanies />} />
                  <Route path="new" element={<CreateCompany />} />

                  <Route path=":companyId" element={<Company />}>
                    <Route index element={<ShowCompany />} />
                    <Route path="edit" element={<EditCompany />} />

                    <Route path="users" element={<ListUsers />} />
                    <Route path="users/new" element={<CreateUser />} />

                    <Route path="frame_contracts" element={<ListFrameContracts />} />
                    <Route path="frame_contracts/new" element={<CreateFrameContract />} />
                    <Route path="frame_contracts/:frameContractId" element={<ShowFrameContract />} />
                    <Route path="frame_contracts/:frameContractId/edit" element={<EditFrameContract />} />
                  </Route>
                </Route>

                <Route path="orders" element={<ListOrders />} />

                <Route path="admin" element={<Admin />}>
                  <Route index element={<Navigate to="/admin/users" />} />

                  <Route path="users">
                    <Route index element={<ListAdminUsers />} />

                    <Route path="new" element={<CreateAdminUser />} />
                  </Route>
                </Route>

                <Route path="settings" element={<Settings />} />
              </Route>

              <Route path="/sessions" element={<Sessions />}>
                <Route path="new" element={<SignIn />} />
                <Route path="otp" element={<SignInOtp />} />
                <Route path="forgot" element={<ForgotPassword />} />
                <Route path="confirm" element={<ConfirmUser />} />
                <Route path="reset" element={<ResetPassword />} />
              </Route>

              <Route
                path="*"
                element={
                  <main style={{ padding: "1rem" }}>
                    <p>Nothing here!</p>
                  </main>
                }
              />
            </Routes>
          </ErrorBoundary>
        </Provider>
      </BrowserRouter>
      <ToastContainer theme="colored" autoClose={10000} />
    </Intl>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
